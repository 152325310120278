.mm-popup {
	display: none;
	z-index: 999999;
	position: relative;

	&--visible {
		display: block;
	}

	&__overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1000;
		overflow: auto;
		background: rgba(0, 0, 0, 0.4);
	}

	&__btn {
		border-radius: 3px;
		box-sizing: border-box;
		padding: 0 10px;
		margin: 0;
		line-height: 32px;
		height: 32px;
		border: 1px solid #666;
		text-align: center;
		display: inline-block;
		font-size: 12px;
		font-weight: bold;
		color: #007aff;
		background: transparent;
		outline: none;
		text-decoration: none;
		cursor: pointer;
	}

	&__btn--success {
		background-color: #27ae60;
		border-color: #27ae60;
		color: #ffffff;
	}
	&__btn--danger {
		background-color: #c5545c;
		border-color: #c5545c;
		color: #ffffff;
	}
	&__box {
		width: 100%;
		max-width: 80%;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: #ffffff;
		box-shadow: 0 5px 20px 0 rgba(126, 137, 140, 0.2);
		border-radius: 8px;
		border: 1px solid #b8c8cc;
		overflow: hidden;
		z-index: 1001;
	}
	&__box__header {
		padding: 10px 10px 0;
		color: black;
	}
	&__box__header__title {
		margin: 0;
		font-size: 16px;
		text-align: center;
		font-weight: bold;
	}
	&__box__body {
		padding: 10px 10px 20px;
		line-height: 1.4;
		font-size: 14px;
		color: black;
		background: #ffffff;
		position: relative;
		z-index: 2;
		text-align: center;
		p {
			margin: 0 0 5px;
		}
	}
	&__box__footer {
		overflow: hidden;
		border-top: 1px solid rgba(186, 186, 186, 0.5);
		display: flex;
		justify-content: space-between;
		align-items: center;
		text-align: center;

		.mm-popup__btn {
			display: block;
			width: 100%;
			text-align: center;
			margin-top: 5px;
			border: none;
			font-size: 14px;
			font-weight: bold;
			background: none;

			&--ok {
				color: #007aff;
			}
		}
	}
	&__box__footer__right-space,
	&__box__footer__left-space {
		position: relative;
		padding: 3px;
		width: 100%;
		text-align: center;

		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			width: 1px;
			background-color: rgba(186, 186, 186, 0.5);
		}

		&:last-of-type:after {
			display: none;
		}

		&:empty {
			display: none;
		}
	}

	&__box__footer__right-space {
		.mm-popup__btn {
			font-weight: normal;
			margin-left: 5px;
		}
	}

	&__box__footer__left-space {
		.mm-popup__btn {
			margin-right: 5px;
		}
	}

	&__box--popover {
		width: 300px;
		margin-left: -150px;

		.mm-popup__box__body {
			padding: 20px;
		}
	}
}
